/* Framework imports ----------------------------------- */
import {
  useEffect,
  useState,
} from 'react';

/* Module imports -------------------------------------- */
import {
  ConnectionStatus,
  Network,
} from '@capacitor/network';

/* useURLObserver hook --------------------------------- */
export const useNetwork = (): ConnectionStatus | undefined => {
  const [ status, setStatus ] = useState<ConnectionStatus>();

  Network.addListener(
    'networkStatusChange',
    (pStatus) => {
      setStatus(pStatus);
    },
  )
    .catch(
      (pError) => {
        console.error(`[ERROR] Network.addListener failed :`, pError);
      },
    );

  useEffect(
    () => {
      Network.getStatus()
        .then(
          (pStatus) => {
            setStatus(pStatus);
          },
        )
        .catch(
          (pError) => {
            console.error(`[ERROR] Network.getStatus failed :`, pError);
            setStatus({ connected: false, connectionType: 'unknown' });
          },
        );
    },
    [],
  );

  return status;
};
